import React, { useState } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"

import Sparkle from "../../assets/images/sparkle_logo_blue.png"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IosShareIcon from "@mui/icons-material/IosShare"

const InstallOptionDialog = () => {
  const [open, setOpen] = useState(true)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div></div>
        <div style={{ textAlign: "center", paddingTop: "1em" }}>
          <img src={Sparkle} style={{ width: "70%", height: "4em" }} />
        </div>
        <DialogTitle
          id="responsive-dialog-title"
          style={{ textAlign: "center", fontSize: "24px" }}
        >
          {"Access the Sparkle app quick and easy"}
        </DialogTitle>
        <div
          style={{
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "bold",
          }}
        >
          3 easy steps!
        </div>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <h2 style={{ margin: 0, paddingBottom: ".5em" }}>Step 1</h2>
            <div>
              for <span style={{ fontWeight: "bold" }}>Android</span>
            </div>
            <div>
              tap the option{" "}
              <MoreVertIcon
                style={{
                  border: "1px solid black",
                  borderRadius: "50%",
                  position: "absolute",
                  marginLeft: ".2em",
                }}
              />
            </div>
            <div>and click "Open using Google Chrome"</div>
            <div style={{ paddingTop: "1em" }}>
              for <span style={{ fontWeight: "bold" }}>IOS</span>
            </div>
            <div>
              tap the option{" "}
              <IosShareIcon
                style={{
                  position: "absolute",
                  marginLeft: ".2em",
                }}
              />
            </div>
            <div>and click "Open using Google Chrome"</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <h2
              style={{ margin: 0, paddingBottom: ".5em", paddingTop: ".5em" }}
            >
              Step 2
            </h2>
            <div>Click the "Save" or "Download" to home screen</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <h2
              style={{ margin: 0, paddingBottom: ".5em", paddingTop: ".5em" }}
            >
              Step 3
            </h2>
            <div>Explore the app, order, play, and enjoy!</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default InstallOptionDialog
