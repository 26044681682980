import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Slide, Stack, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import CollapseAlert from '../../Alerts/CollapseAlert'
import useLoggedUser from '../../../custom-hooks/useLoggedUser'
import { sendDisordHelp } from '../../../api/public/discord'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})



export default function useDiscordHelp() {

  const [open, setopen] = useState(false)

  const showDiscordHelp = () => {
    setopen(true)
  }

  const closeDiscordHelp = () => {
    setopen(false)
  }

  const DiscordHelpDialog = () => {

    const [alertState, setalertState] = useState({
      open: false,
      severity: "success",
      message: "Something happened"
    })

    const [hideSubmit, sethideSubmit] = useState(false)

    const { getUser } = useLoggedUser()

    let user = getUser()

    return (
      <Dialog open={open} fullWidth TransitionComponent={Transition}>
        <DialogContent>
          <Formik
            initialValues={{
              phone: user?.phone,
              email: user?.email
            }}
            onSubmit={async (data) => {
              try {
                await sendDisordHelp({
                  name: user?.name,
                  email: data?.email,
                  phone: data?.phone,
                  description: data?.description
                })

                setalertState({
                  open: true,
                  severity: "success",
                  message: "Report sent! we will get back to you as soon as possible"
                })

                sethideSubmit(true)
              } catch (error) {
                setalertState({
                  open: true,
                  severity: "error",
                  message: error.message
                })
              }
            }}
          >
            {
              ({ isSubmitting, handleChange, values }) => {
                return <Form>
                  <Stack spacing={2}>
                    <TextField
                      name="description"
                      label={"What happened?"}
                      required
                      multiline
                      rows={4}
                      onChange={handleChange}
                      value={values.description}
                    />
                    <TextField
                      name="phone"
                      label={"Phone"}
                      required
                      onChange={handleChange}
                      value={values.phone}
                    />
                    <TextField
                      name="email"
                      label={"Email"}
                      required
                      onChange={handleChange}
                      value={values.email}
                    />
                    <CollapseAlert
                      open={alertState.open}
                      message={alertState.message}
                      severity={alertState.severity}
                      onClose={() => {
                        setalertState((prevState) => ({ ...prevState, open: false }))
                      }}
                    />
                    <DialogActions>
                      <Button onClick={closeDiscordHelp}>Close</Button>
                      {!hideSubmit && <Button disabled={isSubmitting} type="submit">{isSubmitting ? "Loading..." : "Submit"}</Button>}
                    </DialogActions>

                  </Stack>

                </Form>
              }
            }
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }

  return { showDiscordHelp, closeDiscordHelp, DiscordHelpDialog }
}