export const sendDisordHelp = async ({ name, email, phone, description, }) => {

  let reportData = {
    "embeds": [{
      "author": {
        "name": name
      },
      "description": description,
      "footer": {
        "text": `${phone} | ${email}`
      }
    }]
  }

  return await fetch(process.env.GATSBY_DISCORD_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reportData),
  })
    .then((response) => {
      if (!response.status > 200 && response.status > 204) {
        throw Error("Unable to send report!")
      }
      return response
    })
    .catch((err) => {

      throw err
    })
}
