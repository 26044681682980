import React, { useContext, useState, useEffect } from "react"
import SecureLS from "secure-ls"
import { HomeMadeCartContext, CurrentSelectedAddress } from "../../globalstates"
import LoginPage from "../../LoginPage"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Container from "@material-ui/core/Container"
import Toolbar from "@material-ui/core/Toolbar"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import Grid from "@material-ui/core/Grid"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import MotorcycleIcon from "@material-ui/icons/Motorcycle"
import PaymentIcon from "@material-ui/icons/Payment"
import NoteAddIcon from "@material-ui/icons/NoteAdd"
import LocalActivityOutlinedIcon from "@material-ui/icons/LocalActivityOutlined"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import { createOrder, getDeliveryFee } from "../../../api/public/order"
import blue from "@material-ui/core/colors/lightBlue"
import { theme } from "../../../assets/mui"
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles"
import { Backdrop, CircularProgress } from "@material-ui/core"
import CountUp from "react-countup"
import { ThemeProvider } from "@material-ui/styles"
import DateFnsUtils from "@date-io/date-fns"
import { isLoggedIn, getUser } from "../../../services/auth/"
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers"
// import { getDistance } from '../../../api/public/google_api'
// import { getPlatformVariables } from '../../../api/public/platform_vars'
import AddressConfirmationDialog from "./components/AddressConfirmationDialog"
import InfoDialog from "../../Dialogs/InfoDialog"
import ConfirmationDialog from "../../Dialogs/ConfirmationDialog"
import SpecialNotesDialog from "./components/SpecialNotesDialog"
import VoucherDialog from "./components/VoucherDialog"
import PaymentMethodDialog from "./components/PaymentMethodDialog"
import Drawer from "@material-ui/core/Drawer"

import { Link, navigate } from "gatsby"
import {
  getAllShopsV2,
  getDistanceAndDeliveryFee,
} from "../../../api/public/shops"
import { getVoucher } from "../../../api/public/voucher"
import CheckoutItem from "./components/CheckoutItem"
import VoucherCheckoutItem from "./components/VoucherCheckoutItem"
import "./CheckoutPage.css"

import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/analytics"

import socket from "../../../services/socketio"
import { createOrderUpdateBroadcast } from "../../../api/public/broadcast"


import currency from "currency.js"

import useWindowDimensions from "../../../custom-hooks/useWindowDimensions"

// import { useAuthState } from 'react-firebase-hooks/auth';
// import { useCollectionData } from 'react-firebase-hooks/firestore';

const firestore = firebase.firestore()

const useStyles = makeStyles({
  backSize: {
    fontSize: "1.5em",
    color: "black",
  },
  listBackground: {
    backgroundColor: "#F2F7FD",
    borderRadius: "15px",
    marginLeft: "1em",
    marginRight: "1em",
  },
  bold: {
    fontWeight: "100",
    fontFamily: "visby",
  },
  visby: {
    fontFamily: "visby",
  },
  fontColor: {
    color: "black",
  },
  decoration: {
    textDecoration: "none",
    fontFamily: "visby",
    fontSize: "0.9em",
  },
  iconBackGround: {
    backgroundColor: "transparent",
  },
  button: {
    backgroundColor: "#FFCF10",
    borderRadius: "10px",
    height: "3em",
    fontFamily: "visby",
    fontWeight: "bold",
  },
  textField: {
    margin: "50px",
    height: "200px",
  },
  drawer: {
    elevation: 0,
    borderRadius: "10px",
  },
  backdrop: {
    zIndex: 100,
  },
  title: {
    fontFamily: "visby",
    fontWeight: "bold",
  },
  change: {
    marginLeft: "0.3em",
  },
  deliveryInfo: {
    fontFamily: "visby",
    margin: "0 15px",
  },
  deliveryDetails: {
    width: "90%",
    marginLeft: "1em",
  },
  discount: {
    color: "blue",
    fontWeight: "bold",
  },
  mb: {
    marginBottom: "1em",
  },
  grandTotal: {
    width: "90%",
    marginLeft: "0.9em",
    marginTop: "1em",
    marginBottom: "0.8em",
  },
  placeOrderButton: {
    width: "90%",
    marginLeft: "1em",
  },
})

const datePickerTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: blue.A200,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: blue.A800,
      },
      daySelected: {
        backgroundColor: blue["800"],
      },
      dayDisabled: {
        color: blue["800"],
      },
      current: {
        color: blue["800"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: blue["800"],
      },
    },
  },
})

const CheckoutPage = () => {
  let userLoggedIn = isLoggedIn()
  const { height, width } = useWindowDimensions()
  const classes = useStyles()

  // eslint-disable-next-line no-unused-vars
  const [currentSelectedAddress, setcurrentSelectedAddress] = useContext(
    CurrentSelectedAddress
  )

  const [dialogState, setDialogState] = useState({
    showDialog: false,
    dialogMessage: "",
    isError: false,
  })

  const [addressConfDialogState, setaddressConfDialogState] = useState({
    showDialog: false,
    message: "",
  })

  const [backDropState, setbackDropState] = useState(false)

  const [showConfirmationDialog, setshowConfirmationDialog] = useState(false)

  const [showSpecialNotesDialog, setshowSpecialNotesDialog] = useState(false)

  const [showVoucherDialog, setshowVoucherDialog] = useState(false)

  const [showPaymentMethodDialog, setshowPaymentMethodDialog] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [bagItems, updateBag] = useContext(HomeMadeCartContext)

  const [scheduleDrawerState, setscheduleDrawerState] = useState(false)

  const [schedulePickerValue, setSchedulePickerValue] = useState(new Date())

  const [when, setwhen] = useState(new Date(new Date().getTime() + 45 * 60000))

  const [voucher, setvoucher] = useState("")

  const [notes, setNotes] = useState("")
  const [paymentMethod, setpaymentMethod] = useState({ type: "COD", meta: {} })

  const [totalAmount, setTotalAmount] = useState(0.0)
  // eslint-disable-next-line no-unused-vars
  const [deliveryFee, setdeliveryFee] = useState(0.0)

  const [deliveryFeeDiscount, setDeliveryFeeDiscount] = useState(0)
  const [discount, setDiscount] = useState(0)

  const handleDateChange = (date) => {
    setSchedulePickerValue(date)
  }

  async function calculateDeliveryFee() {
    setbackDropState(true)
    const ls = new SecureLS({ encodingType: "aes" })
    const userId = ls.get("userId")

    // let coor = {
    //   startLng: bagItems[0].shop.location.coordinates[0],
    //   startLat: bagItems[0].shop.location.coordinates[1],
    //   endLng: currentSelectedAddress.lng,
    //   endLat: currentSelectedAddress.lat,
    // }

    // let data = await getDistance(
    //   coor.startLng,
    //   coor.startLat,
    //   coor.endLng,
    //   coor.endLat
    // )

    const surgeRef = firestore.doc(`SURGE/ZZZAoCXyyekSyIc2zM0P`)
    const surgeSnap = await surgeRef.get()
    let surge = surgeSnap.data()

    // // setdeliveryFee(surge.isSurge ? shopListWithDeliveryFee[index].deliveryFee+surge.surgeFee: shopListWithDeliveryFee[index].deliveryFee )

    let response = await getDistanceAndDeliveryFee(
      bagItems[0].shop.location.coordinates[1],
      bagItems[0].shop.location.coordinates[0],
      currentSelectedAddress.lat,
      currentSelectedAddress.lng
    )

    setdeliveryFee(
      surge.isSurge
        ? response.deliveryFee + surge.surgeFee
        : response.deliveryFee
    )

    setbackDropState(false)
    //

    //
  }

  function dateToIso(date) {
    let currentDate = date

    let output = `${currentDate.getFullYear()}-${(
      "0" +
      (currentDate.getMonth() + 1)
    ).slice(-2)}-${("0" + currentDate.getDate()).slice(-2)}T${(
      "0" + currentDate.getHours()
    ).slice(-2)}:${("0" + currentDate.getMinutes()).slice(-2)}:${(
      "0" + currentDate.getSeconds()
    ).slice(-2)}`

    return output
  }

  function total(productList) {
    let totality = 0
    for (let index = 0; index < productList.length; index++) {
      totality = totality + productList[index].count * productList[index].price
    }
    setTotalAmount(totality)
    return totality
  }

  useEffect(() => {
    //  getPlatformVariables().then((e) => {
    //   calculateDeliveryFee()
    //  })

    if (bagItems.length <= 0) {
      navigate(-1)
      return
    }

    calculateDeliveryFee()
    total(bagItems)
  }, [])

  function alertUser(message) {
    setDialogState({
      showDialog: true,
      dialogMessage: message,
    })
  }

  async function validateVoucher(voucher) {
    let voucherInformation = await getVoucher(voucher)

    if (voucherInformation.length > 0) {
      //
      let voucher = voucherInformation[0]

      if (voucher?.shops.length > 0) {
        let shopFound = voucher.shops.find(
          (shop) => shop.value === bagItems[0].shop._id
        )

        if (shopFound) {
        } else {
          setvoucher("")
          setDeliveryFeeDiscount(0)
          setDiscount(0)
          return alertUser(`The voucher is not valid for this shop.`)
        }
      }

      if (voucher.product !== "sparkle") {
        setvoucher("")
        setDeliveryFeeDiscount(0)
        setDiscount(0)
        return alertUser("Voucher for sparkle only.")
      }
      //if available
      if (!voucher.available) {
        setvoucher("")
        setDeliveryFeeDiscount(0)
        setDiscount(0)
        return alertUser("Voucher not available")
      }

      //if pasok sa validity period
      if (voucher?.valid_from && voucher?.valid_to) {
        //check if there is validity expiration
        if (
          new Date().getTime() > new Date(voucher.valid_from) &&
          new Date().getTime() < new Date(voucher.valid_to).getTime()
        ) {
        } else {
          setvoucher("")
          setDeliveryFeeDiscount(0)
          setDiscount(0)
          return alertUser("voucher not available, already expired")
        }
      }

      //if on the every date of month
      if (voucher?.every_date_of_month) {
        //get every dates of month
        let dates = voucher.every_date_of_month.split(",")
        if (dates.includes(new Date().getDate().toString())) {
        } else {
          setvoucher("")
          setDeliveryFeeDiscount(0)
          setDiscount(0)
          return alertUser("Voucher not available, not applicable at this date")
        }
      }

      //check if in the area
      if (voucher?.areas) {
        let exist = false
        voucher.areas
          .toLowerCase()
          .replace(" ", "")
          .split(",")
          .forEach((area) => {
            // find in the current address

            if (
              currentSelectedAddress.address
                .toLowerCase()
                .includes(area.toString())
            )
              exist = true
          })

        if (!exist) {
          setvoucher("")
          setDeliveryFeeDiscount(0)
          setDiscount(0)
          return alertUser("Voucher not available, not applicable in your area")
        }

        /*
        let area = currentSelectedAddress.address.split(',')[currentSelectedAddress.address.split(',').length - 2].toString().replace(" ", "").toLowerCase();
        if(voucher.areas.toLowerCase().replace(" ", "").split(',').includes(area.toString())){
          
        }else{
          setvoucher('')
          return alert('voucher not available, not applicable on your area')
        }*/
      }

      //if one time use
      if (voucher?.one_time_use) {
        //check if includes the user id

        if (voucher.users_availed.includes(getUser().userId)) {
          setvoucher("")
          setDeliveryFeeDiscount(0)
          setDiscount(0)
          return alertUser("Voucher not available, you already availed this")
        }
      }

      if (voucher?.minimum_purchase) {
        if (totalAmount >= voucher?.minimum_purchase) {
        } else {
          setvoucher("")
          setDeliveryFeeDiscount(0)
          setDiscount(0)
          return alertUser(
            `must equate or exceed minimum purchase of ${voucher.minimum_purchase}`
          )
        }
      }

      //apply delivery fee discount
      if (voucher?.delivery_fee_discount) {
        if (voucher.delivery_fee_discount === "free") {
          setDeliveryFeeDiscount(deliveryFee)
        } else {
          if (voucher.delivery_fee_discount.includes("%")) {
            //percentage

            if (
              deliveryFee *
              (Number(voucher.delivery_fee_discount.replace("%", "")) /
                100) >=
              deliveryFee
            ) {
              setDeliveryFeeDiscount(deliveryFee)
            } else
              setDeliveryFeeDiscount(
                deliveryFee *
                (Number(voucher.delivery_fee_discount.replace("%", "")) / 100)
              )
          } else {
            if (voucher.delivery_fee_discount >= deliveryFee)
              setDeliveryFeeDiscount(deliveryFee)
            else setDeliveryFeeDiscount(Number(voucher.delivery_fee_discount))
          }
        }
      }

      //voucher amount if percentage
      if (voucher.amount.includes("%")) {
        let percentage = Number(voucher.amount.replace("%", "")) / 100
        setDiscount(totalAmount * percentage)
      } else {
        if (voucher.amount >= totalAmount) setDiscount(totalAmount)
        else setDiscount(Number(voucher.amount))
      }
    } else {
      alertUser("Sorry, voucher not found!")
      setvoucher("")
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar color="primary">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => {
              navigate(-1)
            }}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography className={classes.title}>
            <div>Checkout</div>
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container maxWidth="xs" disableGutters="true">
        <Box pt={3}></Box>
        {/* Schedule Picker */}
        {!userLoggedIn ? (
          <LoginPage />
        ) : (
          <div>
            <CheckoutItem
              icon={<CalendarTodayIcon className={classes.fontColor} />}
              title={"When to Deliver"}
              subtitle={`${when.toLocaleDateString()} ${when.toLocaleTimeString()}`}
              onClick={(e) => {
                e.preventDefault()
                setscheduleDrawerState(!scheduleDrawerState)
              }}
            />
            <Box pt={1}></Box>
            {/* Map Picker */}
            <CheckoutItem
              icon={<LocationOnIcon className={classes.fontColor} />}
              title={"Deliver to"}
              subtitle={currentSelectedAddress.address}
              onClick={(e) => {
                navigate("/MapPage")
              }}
            />
            <Box pt={1}></Box>
            {/* Courier Picker */}
            <CheckoutItem
              icon={<MotorcycleIcon className={classes.fontColor} />}
              title={"Courier"}
              subtitle={"SPARK EXPRESS"}
              onClick={(e) => { }}
            />
            <Box pt={1}></Box>
            {/* Payment Type */}
            <CheckoutItem
              icon={<PaymentIcon className={classes.fontColor} />}
              title={"Payment method"}
              subtitle={paymentMethod.type}
              onClick={(e) => {
                e.preventDefault()
                setshowPaymentMethodDialog(true)
              }}
            />
            <Box pt={1}></Box>
            {/* Special Notes */}
            <CheckoutItem
              icon={<NoteAddIcon className={classes.fontColor} />}
              title={"Special Instruction"}
              subtitle={!notes ? "Add additional notes" : notes}
              onClick={(e) => {
                e.preventDefault()
                setshowSpecialNotesDialog(true)
              }}
            />
            <Box pt={1}></Box>
            {/* Set Voucher */}
            <VoucherCheckoutItem
              icon={<LocalActivityOutlinedIcon className={classes.fontColor} />}
              title={"Apply a voucher"}
              subtitle={!voucher ? "Please enter code" : voucher}
              onClick={(e) => {
                e.preventDefault()
                setshowVoucherDialog(true)
              }}
              onCancel={(e) => {
                e.preventDefault()

                setvoucher("")
                setDeliveryFeeDiscount(0)
                setDiscount(0)
              }}
            />
            <div
              style={{
                backgroundColor: "white",
                margin: "10px",
                width: "98%",
                bottom: "10px",
                fontFamily: "visby",
              }}
            >
              <Grid container className={classes.deliveryDetails}>
                <Grid item xs>
                  <Typography variant="body1" className={classes.deliveryInfo}>
                    Items
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={classes.deliveryInfo}>
                    P {totalAmount}
                  </Typography>
                </Grid>
              </Grid>
              {discount > 0 ? (
                <Grid
                  container
                  className={`${classes.deliveryDetails} ${classes.discount} ${classes.mb}`}
                >
                  <Grid item xs>
                    <Typography variant="body1" className={classes.deliveryInfo}>
                      Discount
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" className={classes.deliveryInfo}>
                      - P {discount}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              <Grid container className={classes.deliveryDetails}>
                <Grid item xs>
                  <Typography variant="body1" className={classes.deliveryInfo}>
                    Delivery fee
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={classes.deliveryInfo}>
                    P <CountUp start={0} end={deliveryFee} duration={1} />
                  </Typography>
                </Grid>
              </Grid>
              {deliveryFeeDiscount > 0 ? (
                <Grid
                  container
                  className={`${classes.deliveryDetails} ${classes.discount}`}
                >
                  <Grid item xs>
                    <Typography variant="body1" className={classes.deliveryInfo}>
                      Delivery fee Discount
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" className={classes.deliveryInfo}>
                      - P{" "}
                      <CountUp start={0} end={deliveryFeeDiscount} duration={1} />
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}

              <Grid container>
                <Grid item xs>
                  <Typography
                    variant="h5"
                    className={`${classes.deliveryInfo} ${classes.bold}`}
                  >
                    Grand Total
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    className={`${classes.deliveryInfo} ${classes.bold}`}
                  >
                    P{" "}
                    {deliveryFee - deliveryFeeDiscount + (totalAmount - discount)}
                  </Typography>
                </Grid>
              </Grid>
              <Box mx="auto" pt={1}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    setaddressConfDialogState((prevState) => ({
                      ...prevState,
                      showDialog: true,
                    }))
                  }}
                >
                  Place Order
                </Button>
              </Box>
            </div>
            <Drawer
              anchor="bottom"
              open={scheduleDrawerState}
              onClose={() => {
                setscheduleDrawerState(!scheduleDrawerState)
              }}
              className={classes.drawer}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    borderRadius: "10px",
                    margin: "10px",
                    width: "50px",
                    height: "5px",
                    background: "gray",
                  }}
                />
              </div>

              <ThemeProvider theme={datePickerTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div
                    style={{
                      display: "inline-grid",
                      margin: "0 50px 100px 50px",
                    }}
                  >
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Delivery Date"
                      format="MM/dd/yyyy"
                      value={schedulePickerValue}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Delivery Time"
                      value={schedulePickerValue}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                    <Divider />
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.button}
                      onClick={async () => {
                        setwhen(schedulePickerValue)
                        setscheduleDrawerState(!scheduleDrawerState)
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </Drawer>
            <InfoDialog
              showDialog={dialogState.showDialog}
              message={dialogState.dialogMessage}
              onConfirm={() => {
                setDialogState((prevState) => ({
                  ...prevState,
                  showDialog: false,
                }))
              }}
            />
            {/* <ConfirmationDialog
            showDialog={showConfirmationDialog}
            message="Place order?"
            onConfirm={async () => {
              setshowConfirmationDialog(false)
              if (bagItems.length <= 0) {
                setDialogState((prevState) => ({
                  ...prevState,
                  showDialog: true,
                  dialogMessage:
                    "Your bag is empty for some reason, please add products to bag and try again",
                }))
                return
              }

              const ls = new SecureLS({ encodingType: "aes" })
              const userId = ls.get("userId")
              //const newDate = new Date().toISOString()

              // if (paymentMethod.type.includes("Credit")) {
                // get payment Method
                // let payM = paymentMethod.meta.data.id
                // add paymentIntent

                try {
                  // const payI = await paymongo.paymentIntents.create({
                  //   data: {
                  //     attributes: {
                  //       amount: currency(
                  //         deliveryFee -
                  //           deliveryFeeDiscount +
                  //           (totalAmount - discount)
                  //       ).intValue, // add the 6% and other fees here
                  //       payment_method_allowed: ["card"],
                  //       payment_method_options: {
                  //         card: { request_three_d_secure: "any" },
                  //       },
                  //       currency: "PHP",
                  //       description: `Items ${bagItems[0].name}`,
                  //       statement_descriptor: `Sparkle Taste of Home: ${bagItems[0].shop.name}`,
                  //       metadata: {
                  //         id: "1231231Order", //will be transfered to the backend
                  //         chomperName: "ChomperFirst ChomperLastNameOrder",
                  //       },
                  //     },
                  //   },
                  // })

                  // const attachPayI = await paymongo.paymentIntents.attach(
                  //   payI.data.id,
                  //   {
                  //     data: {
                  //       attributes: {
                  //         payment_method: payM,
                  //         client_key: payI.data.attributes.client_key,
                  //       },
                  //     },
                  //   }
                  // )

                  if (attachPayI) {
                    alert("Payment Success")

                    // TODO: redundant code - for clean up

                    let orderData = {
                      order: {
                        products: bagItems,
                        address: currentSelectedAddress.address,
                        deliveryFee: deliveryFee,
                        amount: totalAmount,
                        when: dateToIso(when),
                        paymentType: "paymentMethod.type",
                        deliveryNotes: notes,
                        shop: bagItems[0].shop._id ?? "",
                        voucher: voucher,
                        lat: currentSelectedAddress.lat,
                        long: currentSelectedAddress.lng,
                        deliveryFeeDiscount: deliveryFeeDiscount,
                        amountDiscount: discount,
                        paymentMethod: paymentMethod.meta,
                      },
                    }

                    setbackDropState(false)

                    createOrder(userId, orderData).then((response) => {
                      if (response.status === 200) {
                        response.json().then((result) => {
                          setDialogState((prevState) => ({
                            ...prevState,
                            showDialog: true,
                            dialogMessage:
                              "Order created please wait for your order to get delivered",
                          }))
                          navigate("/ongoingOrderDetails", {
                            state: {
                              order: result,
                            },
                          })

                          createOrderUpdateBroadcast({
                            title: `Hi your order is created`,
                            body: "Please wait for the merchant to accept it, Thank you.",
                          }).then((response) => {
                            if (response.status === 200) {
                              response.json().then((broadcast) => {
                                socket.emit(
                                  "emit-selected-broadcasts",
                                  [userId],
                                  { ...broadcast, isRead: false }
                                )
                              })
                            }
                          })

                          socket.emit("create-order", JSON.stringify(result))

                          updateBag([])
                          setbackDropState(false)
                        })
                      } else if (response.status === 400) {
                        response.json().then((result) => {
                          setDialogState((prevState) => ({
                            ...prevState,
                            showDialog: true,
                            dialogMessage: `${result.error}`,
                          }))
                          setbackDropState(false)
                        })
                      } else {
                        response.json().then((result) => {
                          setDialogState((prevState) => ({
                            ...prevState,
                            showDialog: true,
                            dialogMessage: `${result.error}`,
                          }))
                          setbackDropState(false)
                        })
                      }
                    })
                  } else {
                    alert("Payment Failed")
                  }
                } catch (e) {
                  alert(e.detail.replace("details.", ""))
                }
              } else {
                let orderData = {
                  order: {
                    products: bagItems,
                    address: currentSelectedAddress.address,
                    deliveryFee: deliveryFee,
                    amount: totalAmount,
                    when: dateToIso(when),
                    paymentType: paymentMethod.type,
                    deliveryNotes: notes,
                    shop: bagItems[0].shop._id ?? "",
                    voucher: voucher,
                    lat: currentSelectedAddress.lat,
                    long: currentSelectedAddress.lng,
                    deliveryFeeDiscount: deliveryFeeDiscount,
                    amountDiscount: discount,
                    paymentMethod: paymentMethod.meta,
                  },
                }

                setbackDropState(false)

                createOrder(userId, orderData).then((response) => {
                  if (response.status === 200) {
                    response.json().then((result) => {
                      if(orderData.paymentType === "GCash") {
                        setDialogState((prevState) => ({
                          ...prevState,
                          showDialog: true,
                          dialogMessage:
                            "Order created, payment required to proceed.",
                        }))
                      } else {
                        setDialogState((prevState) => ({
                          ...prevState,
                          showDialog: true,
                          dialogMessage:
                            "Order created please wait for your order to get delivered",
                        }))
                      }
                      
                      navigate("/ongoingOrderDetails", {
                        state: {
                          order: result,
                        },
                      })

                      createOrderUpdateBroadcast({
                        title: `Hi your order is created`,
                        body: "Please wait for the merchant to accept it, Thank you.",
                      }).then((response) => {
                        if (response.status === 200) {
                          response.json().then((broadcast) => {
                            socket.emit("emit-selected-broadcasts", [userId], {
                              ...broadcast,
                              isRead: false,
                            })
                          })
                        }
                      })

                      socket.emit("create-order", JSON.stringify(result))

                      updateBag([])
                      setbackDropState(false)
                    })
                  } else if (response.status === 400) {
                    response.json().then((result) => {
                      setDialogState((prevState) => ({
                        ...prevState,
                        showDialog: true,
                        dialogMessage: `${result.error}`,
                      }))
                      setbackDropState(false)
                    })
                  } else {
                    response.json().then((result) => {
                      setDialogState((prevState) => ({
                        ...prevState,
                        showDialog: true,
                        dialogMessage: `${result.error}`,
                      }))
                      setbackDropState(false)
                    })
                  }
                })
              }
            }}
            onDecline={() => {
              setshowConfirmationDialog(false)
              setbackDropState(false)
            }}
          /> */}
            <AddressConfirmationDialog
              showDialog={addressConfDialogState.showDialog}
              message={`${currentSelectedAddress.address}`}
              onConfirm={() => {
                setaddressConfDialogState((prevState) => ({
                  ...prevState,
                  showDialog: false,
                }))
                setshowConfirmationDialog(true)
              }}
              onDecline={() => {
                setaddressConfDialogState((prevState) => ({
                  ...prevState,
                  showDialog: false,
                }))
              }}
            />
            <SpecialNotesDialog
              showDialog={showSpecialNotesDialog}
              onConfirm={(notes) => {
                setNotes(notes)
                setshowSpecialNotesDialog(false)
              }}
              onCancel={() => {
                setshowSpecialNotesDialog(false)
              }}
            />
            <PaymentMethodDialog
              currentPMethod={paymentMethod.type}
              showDialog={showPaymentMethodDialog}
              onConfirm={(pMethod) => {
                setpaymentMethod(pMethod)
                setshowPaymentMethodDialog(false)
              }}
              onCancel={() => {
                setshowPaymentMethodDialog(false)
              }}
            />
            <VoucherDialog
              showDialog={showVoucherDialog}
              onConfirm={(voucher) => {
                setvoucher(voucher)
                validateVoucher(voucher)
                setshowVoucherDialog(false)
              }}
              onCancel={() => {
                setshowVoucherDialog(false)
              }}
            />
            <Backdrop
              className={classes.backdrop}
              open={backDropState}
            // onClick={() => {
            //   setbackDropState()
            // }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Container>
    </MuiThemeProvider>
  )
}

export default CheckoutPage